@import "../styles/variables.less";

.app-wrapper {
  min-height: 100vh !important;
  .ant-layout {
    .app-header-wrapper {
      position: sticky;
      top: 0;
      z-index: 999;
      border-bottom: 1px solid @border-color;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .app-header-left {
        .app-header-logo {
          width: 150px;
        }
      }
      .app-header-right {
        display: flex;
        align-items: center;
        .scheduler-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            transition: all 0.3s ease-in-out;
            fill: @primary-color;
            margin-right: 0.25rem;
          }
          &:hover {
            svg {
              fill: @nav-dark-bg;
            }
          }
        }
        .app-header-user-profile {
          cursor: pointer;
          border-radius: 4px;
          width: 220px;
          .ant-card-body {
            padding: 4px 8px;
            display: flex;
            align-items: center;
            .app-header-user-avatar {
              margin-right: 12px;
            }
            .app-header-user-data {
              display: flex;
              flex-direction: column;
              .user-name {
                font-weight: 700;
                letter-spacing: 1px;
              }
              .company-name {
                text-transform: capitalize;
              }
              .user-name,
              .company-name {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .app-content-wrapper {
      height: calc(100vh - 128px);
      background: @white-color;
      .no-delegation {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-wrapper {
          padding: 2rem 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h4 {
            text-align: center;
          }
          .no-delegation-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            div {
              padding: 1rem 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              span {
                text-align: center;
              }
            }
            .text-with-link {
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
    }
    .app-footer-wrapper {
      z-index: 1;
      border-top: 1px solid @border-color;
      padding: 0 2rem;
      height: 64px;
      display: flex;
      align-items: center;
      .footer-row {
        width: 100%;
        div:not(:first-of-type) {
          text-align: left;
        }
        .app-version {
          color: rgba(0, 0, 0, 0.25)
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .app-wrapper {
    .ant-layout {
      .app-content-wrapper {
        height: unset;
        min-height: calc(100vh - 128px);
        .no-delegation {
          height: calc(100vh - 128px) !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .app-wrapper {
    .ant-layout {
      .app-footer-wrapper {
        height: unset;
        padding: 1rem 0;
        .footer-row {
          .ant-col {
            padding: 8px 0;
            text-align: center;
          }
          .app-version {
            text-align: left;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .app-wrapper {
    .ant-layout {
      .app-header-wrapper {
        // TODO: Remove overflow once placement of all header component decided
        overflow-x: auto;
        padding: 0 1rem;
        .app-header-left {
          .app-header-logo {
            width: 110px;
          }
        }
        .app-header-right {
          .app-header-user-profile {
            width: 150px;
            .ant-card-body {
              .app-header-user-data {
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .no-delegation {
        &-wrapper {
          padding: 1rem 0;
          h4 {
            font-size: 20px;
          }
          .no-delegation-content {
            div {
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
