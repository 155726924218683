.marketplace {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-div {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-typography {
      text-align: center;
    }
    .cta-btn {
      margin: 1rem 0;
      button {
        margin: 0 0.5rem;
      }
    }
    .delegation-note {
      margin-top: 0.5rem;
      max-width: 850px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .marketplace {
    height: calc(100vh - 128px) !important;
  }
}

@media only screen and (max-width: 767px) {
  .marketplace {
    &-div {
      padding: 1rem 0;
      h3 {
        font-size: 20px;
      }
      .cta-btn {
        button {
          width: 100%;
          margin: 0;
          &:first-child {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
