.pricing {
  height: inherit;
  overflow-x: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-heading {
    h1 {
      margin-bottom: 0;
    }
  }
  &-mode,
  &-plans {
    margin-top: 3rem;
  }
  &-mode {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }
  &-plans {
    display: flex;
    flex-direction: column;
    .fixed-plans {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .pricing-plan {
        width: 300px;
        margin: 8px;
        transition: all 0.4s ease-in-out;
        &:hover {
          box-shadow: 0 4px 22px rgba(202, 202, 202, 0.5);
          transform: scale(1.02);
        }
        .ant-card-body {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .pricing-plan-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            div {
              margin-bottom: 1rem;
            }
            &-name,
            &-price {
              h4 {
                margin-bottom: 0;
              }
            }
            &-price {
              h4 {
                color: @primary-color;
              }
            }
            &-features {
              display: flex;
              flex-direction: column;
              span {
                margin-bottom: 0.5rem;
              }
            }
          }
          .pricing-plan-button {
            margin-top: auto;
            width: 100%;
            button {
              width: inherit;
            }
          }
        }
      }
    }
    .enterprise-plans {
      margin: 8px;
      .ant-card {
        .ant-card-body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.cb-pricing-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      display: flex;
      justify-content: center;
      border-radius: 10px;
      .ant-modal-title {
        color: #535353;
        font-weight: 600;
        h2 {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-body {
      padding: 2rem 3rem 2rem 3rem;
      // padding: 50px;
      font-size: 16px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 991px) {
  .pricing {
    &-plans {
      .enterprise-plans {
        .ant-card {
          .ant-card-body {
            flex-direction: column;
            h4,
            span {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
