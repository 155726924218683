.user-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .cb-fix-div {
    .user-list-heading-wrapper {
      height: 40px;
      background-color: @white-color;
      border-bottom: 1px solid @border-color;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading-left {
        display: flex;
        align-items: center;
        .user-list-heading {
          span {
            font-size: 1rem;
          }
        }
        .cb-svg {
          display: flex;
          margin-right: 0.5rem;
        }
      }
      .heading-right {
        span {
          font-size: 1rem;
        }
      }
    }

    .details-header-wrapper {
      height: 40px;
      background-color: @white-color;
      border-bottom: 1px solid @border-color;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading-users {
        padding: 0 1rem;
        display: flex;
        flex: 1 1;
        align-items: flex-start;
        max-width: 100%;
        .cb-svg {
          display: flex;
          margin-right: 0.5rem;
        }
      }
      .heading-status {
        text-align: center;
        width: 20%;
        .ant-typography {
          padding-right: 0.5rem;
        }
      }
      .heading-action {
        flex: 0 0 auto;
        margin: 0 15px;
        text-align: center;
        padding: 0;
        width: 20%;
      }
      .heading-permission {
        flex: 0 0 auto;
        // margin: 0 15px;
        text-align: center;
        padding: 0;
        // width: 20%;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
    .add-user-btn {
      border-color: @border-color;
      border-style: solid;
      border-width: 1px 0 1px 0;
      width: 100%;
      border-radius: 0;
      button {
        width: inherit;
        border-radius: unset;
        border: none;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
  &-content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-spin-nested-loading {
      height: inherit;
      .ant-spin {
        max-height: unset;
      }
      .ant-spin-container {
        height: inherit;
        .ant-list-empty-text {
          height: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .cb-list-item {
      padding: 12px 8px;
      &-data {
        .ant-list-item-meta-content {
          .ant-list-item-meta-description {
            font-size: 12px;
            margin-top: 4px;
            letter-spacing: 1.5px;
            font-weight: 700;

            p {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
            }
          }
          .ant-list-item-meta-description {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: horizontal;
            overflow: hidden;
          }
        }
      }
      .user-share-status {
        text-align: left;
        width: 23%;
        padding-right: 8rem;
        font-size: 13px;
      }
      .ant-list-item-action {
        display: flex;
        justify-content: center;
        margin: 0 15px;
        width: 20%;
        .ant-list-item-action-split {
          opacity: 0;
        }
        li:nth-child(1) {
          margin-left: -15px;
        }
        li:nth-child(2) {
          margin: 0 20px 0 auto;

          .ant-select {
            margin-right: -45px;
          }
        }
      }
      .cb-display-rights {
        margin-right: -23px;
      }
    }
  }
  .cb-empty {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .user-list {
    .cb-fix-div {
      .add-user-btn {
        border: 1px solid @border-color;
      }
    }
  }
}
