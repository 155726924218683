.cb-height-inherit,
.cb-height-inherit > .ant-card-body {
  height: inherit;
}

.ant-list-item-meta-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//! Group, Contact & User List CSS

.cb-contact-checkbox {
  position: relative;
  opacity: 0;
  margin-left: -27px;
  padding-right: 8px;
  .ant-checkbox, .ant-checkbox-inner {
    transform: scale(1.2);
  }
  .ant-checkbox.ant-checkbox-checked {
    border-color: transparent;
  }
}

.cb-list-hover {
  cursor: pointer;
  background: @list-hover-background;

  .cb-group-contact-list .ant-list-item-meta-description {
    opacity: 0.5;
  }
  .cb-group-contact-list {
    .ant-avatar {
      opacity: 0;
    }
    .cb-contact-checkbox {
      opacity: 1;
    }
  }
}

.cb-list-item {
  border-bottom: 0 !important;
  .cb-group-list-hover {
    &:hover {
      .ant-avatar {
        opacity: 0;
      }
      .cb-contact-checkbox {
        opacity: 1;
      }
    }
  }
  &:hover {
    cursor: pointer;
    background: @list-hover-background;

    .cb-group-contact-list .ant-list-item-meta-description {
      opacity: 0.6;
    }
  }
  &-data {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    .ant-list-item-meta-content {
      h4 {
        margin-bottom: 0;
        width: 92%;
      }
    }
  }
  .cb-group-contact-list .ant-list-item-meta-description {
    display: flex;
    justify-content: flex-end;
    opacity: 0.5;
    margin-top: -24px;
    .cb-disable-btn {
      opacity: 0.5;
      pointer-events: none;
    }
    .cb-hover-edit {
      z-index: 9999;
    }
  }
}

.cb-selected-group {
  background: @selected-list-background;
}

.cb-group-name-message {
  .cb-group-name-message-strong {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
  }
}

//! Fix Div

.cb-fix-div {
  position: sticky;
  top: 64px;
  z-index: 1;
}

//! Empty Box

.cb-empty {
  .ant-empty-description {
    text-transform: uppercase;
  }
}

//! SVGs

.cb-svg {
  width: 24px;
  height: 24px;
}

.cb-svg-lg {
  width: 48px;
  height: 48px;
}

.cb-svg-sm {
  width: 16px;
  height: 16px;
}

.cb-svg-span {
  svg {
    width: 24px;
    height: 24px;
  }
}

//! Icons

.cb-icons {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
}

//! Group Filter Menu

.cb-filter-menu {
  border-right: 0;
}

.ant-popover-inner {
  width: 200px;
  .ant-popover-inner-content {
    padding: 0;
  }
}

.cb-tooltip-description {
  font-size: 12px;
}

.cb-tooltip-sync-logs-error {
  font-size: 10px;
  p {
    font-weight: 700;
    letter-spacing: 1px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//! Popconfirm

.ant-popconfirm {
  .ant-popover-content {
    .ant-popover-inner {
      width: 300px;
      padding: 8px 16px;
    }
  }
}

//! Menu Item

.cb-menu-item {
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
  }
}

.cb-user-info {
  padding: 8px 16px 4px !important;
  height: unset !important;
  line-height: initial !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  .ant-typography {
    font-size: 12px;
    width: 100%;
    &:first-child {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.cb-divider {
  margin: 0;
}

.ant-popover-arrow {
  display: none;
}

//! Text Alignment

.cb-text-center {
  text-align: center;
}

//! Font

.cb-text-strong {
  font-weight: 700;
  letter-spacing: 1px;
}

.cb-font-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;
  overflow: hidden;
}

//! Scrollbar

.cb-scrollbar {
  ::-webkit-scrollbar {
    width: @scrollbar-width;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: @scrollbar-track-background;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @scrollbar-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: @scrollbar-hover-color;
  }
}

//! Button

.cb-table-delete-button {
  padding-left: 0;
}

.cb-danger {
  font-weight: 700;
  letter-spacing: 1px;
  color: #f5222d;
}

//! Padding - p1 => 4x, p2 => 8px

.cb-pl1 {
  padding-left: 4px;
}

//! Ant Table Tag

.ant-table-cell {
  .ant-tag {
    margin: 4px;
  }
}

//! Loading & Error

.cb-loading-wrapper,
.cb-error-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cb-infinite-loading {
  padding: 12px 0;
  width: 100%;
}

.cb-data-fecth-error-wrapper {
  height: calc(100vh - 128px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    height: calc(50vh - 64px);
    .ant-result-500 {
      .ant-result-image {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 731px) {
    height: 60vh;
  }
}

.cb-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-result-image {
    margin: unset;
  }
}

//! Contact Info

.cb-contact-info-loading {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

//! Share User Modal

.cb-share-user-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: @cb-modal-border-radius;
    .ant-modal-header,
    .ant-modal-footer {
      border: none;
    }
    .ant-modal-header {
      border-radius: @cb-modal-border-radius @cb-modal-border-radius 0 0;
    }
    .ant-modal-body {
      .cb-share-user-select {
        width: 100%;
      }
    }
  }
}

#add_user_form_in_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 12px;
  .ant-form-item {
    width: 100%;
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

#cb-divider {
  margin: 4px 0;
}

.cb-checkbox {
  margin-bottom: 0;
}

//! Consent Modal

.cb-consent-modal,
.cb-migration-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: @cb-modal-border-radius;
    .ant-modal-header {
      border-radius: @cb-modal-border-radius @cb-modal-border-radius 0 0;
      border-bottom: none;
    }
    .ant-modal-body {
      .ant-input[disabled] {
        color: #545454;
      }
      .anticon {
        svg {
          fill: #545454;
        }
      }
    }
    .ant-modal-footer {
      border-top: none;
      padding: 0 24px 16px 24px;
      button {
        width: 30%;
        @media only screen and (max-width: 575px) {
          width: 100%;
        }
      }
    }
  }
}

.cb-cancel-subscription-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: @cb-modal-border-radius;
    .ant-modal-header {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .cb-cancel-title {
    margin-bottom: 1.5rem;
  }
  .cb-cancel-detail {
    list-style-type: disc;
    &-list {
      padding-bottom: 10px;
    }
  }
  .ant-modal-footer {
    .ant-btn-primary {
      width: 80.33px;
    }
  }
}

.cb-scheduler-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: @cb-modal-border-radius;
    .ant-modal-body {
      padding: 48px 24px;
    }
  }
}

.cb-payment-success-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: @cb-modal-border-radius;
    .ant-modal-body {
      .cb-payment-result {
        padding: 32px;
        .ant-result-title {
          line-height: unset;
          margin-bottom: 1rem;
        }
        .ant-result-subtitle {
          line-height: unset;
          .subtitle-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
              margin-top: 1rem;
              width: 200px;
            }
          }
        }
        @media only screen and (max-width: 575px) {
          padding: 0;
          .ant-result-title {
            font-size: 20px;
          }
          .ant-result-subtitle {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.cb-migration-modal {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        h4 {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-body {
      border-top: 1px solid @border-color;
      .migration-text-wrapper {
        .migration-message {
          margin: 1rem 0;
          &:first-of-type {
            margin-top: 0;
          }
        }
        .steps {
          ul {
            list-style-type: decimal;
            li {
              padding: 0.5rem 0;
              margin-left: 1rem;
              &::marker {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 575px) {
  .cb-migration-modal {
    .ant-modal-content {
      .ant-modal-body {
        height: 400px;
        overflow-y: auto;
        padding: 16px 24px;
        .migration-text-wrapper {
          .migration-message {
            margin: 0.5rem 0;
            span {
              font-size: 12px;
            }
          }
          .steps {
            margin: 0.5rem 0;
            ul {
              font-size: 12px;
            }
          }
        }
      }
      .ant-modal-footer {
        border-top: 1px solid @border-color;
        padding: 16px 24px;
        flex-direction: column;
        span {
          margin-bottom: 8px;
        }
      }
    }
  }
}

//! Logs History

.cb-logs-heading {
  h4 {
    text-transform: uppercase;
  }
}

//! Tooltip

.cb-tooltip-card {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 0;
    }
  }
}

.cb-label-tag {
  padding: 8px;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .label-name {
    margin: 4px 0;
    border: none;
    background-color: transparent;
  }
}

//! Table Search

.subscribed-user-table {
  table {
    tbody {
      tr {
        td:first-child {
          letter-spacing: 1px;
          font-weight: 700;
        }
      }
    }
  }
}

.cb-table-search-filter-icon {
  color: @primary-color;
}

.cb-table-search {
  width: 300px;
  padding: 8px;
  &-input {
    margin-bottom: 8px;
  }
}

//! Timezone Modal Body

.cb-time-picker-popup {
  width: 250px;
  .ant-picker-panel-container {
    .ant-picker-panel {
      width: 100%;
      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-ok {
            width: 100px;
            button {
              width: inherit;
            }
          }
        }
      }
    }
  }
}

.cb-scheduler-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-typography {
    display: flex;
    align-items: center;
    line-height: unset;
    font-size: 20px;
    .cb-scheduler-tooltip {
      margin-left: 0.5rem;
    }
  }
  .cb-time-picker-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-form-item {
      margin-bottom: 0;
      width: inherit;
      .ant-form-item-control {
        .ant-form-item-explain {
          display: flex;
          justify-content: center;
        }
        &-input {
          &-content {
            display: flex;
            justify-content: center;
            .cb-time-picker {
              width: 70%;
              margin: 0.5rem 0;
              @media only screen and (max-width: 575px) {
                width: 90%;
              }
            }
            .cb-schedule-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 0.5rem;
              button {
                margin: 0 0.25rem;
              }
            }
            .cb-btn-header {
              width: 70%;
              @media only screen and (max-width: 575px) {
                width: 90%;
              }
              button {
                margin: 0;
                width: 100%;
              }
            }
            .cb-btn-dashboard {
              width: 70%;
              @media only screen and (max-width: 575px) {
                width: 90%;
              }
              button {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

//! Cursor

.cb-cursor {
  cursor: pointer;
}

//! Icon

.cb-edit-icon {
  display: flex;
  // width: 63%;
  justify-content: flex-end;
  opacity: 0.4;
  height: 20px;
  margin-right: 30px;
  margin-top: -3px;
  cursor: pointer;
  flex: auto;
}

.cb-upgrade-modal, .cb-empty-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      display: flex;
      justify-content: center;
      border-radius: 10px;
      .ant-modal-title {
        color: #535353;
        font-weight: 600;
        h2 {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-body {
      padding: 2rem 3rem 2rem 3rem;
      // padding: 50px;
      font-size: 16px;
      text-align: center;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    span {
      padding-bottom: 1.5rem;
    }
    button {
      width: 30%;
    }
  }
}
.cb-delete-contact-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
}