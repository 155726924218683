@import "../../styles/variables.less";

.dashboard {
  position: relative;
  height: 100%;
  &-row {
    .info-wrapper {
      padding: 0.5rem;
      border-bottom: 1px solid @border-color;
      background-color: @gold-3;
      span {
        text-align: center;
        display: flex;
        justify-content: center;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
    .dashboard-col {
      // padding: 1rem 0;
      border-right: 1px solid @border-color;
    }
    .cb-height-inherit-free {
      height: calc(100vh - 160.5px);
    }
  }
}

@media only screen and (max-width: 991px) {
  .dashboard {
    &-row {
      .dashboard-col {
        border-bottom: 1px solid @border-color;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dashboard {
    &-row {
      .dashboard-col {
        height: calc(50vh - 64px);
      }
    }
  }
}

@media only screen and (max-width: 731px) {
  .dashboard {
    &-row {
      .dashboard-col {
        height: 60vh;
        border-right: none;
        border-bottom: 1px solid @border-color;
      }
    }
  }
}
