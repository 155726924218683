.sync-wrapper {
  display: flex;
  margin: 0 8px;
  .sync-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .sync-icon {
      display: flex;
      justify-content: center;
    }
  }
}
