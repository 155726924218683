.cb-contact-info-wrapper {
  .ant-modal-content {
    overflow: hidden;
    border-radius: 10px;
    .ant-modal-close {
      top: 12.5px;
    }
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        display: flex;
        align-items: center;
        .ant-avatar {
          margin-right: 1rem;
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      max-height: 450px;
      overflow-y: auto;
      .cb-contact-info-loading {
        height: inherit;
      }
      .cb-contact-modal-body {
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        .cb-text-strong {
          font-size: 18px;
          margin-bottom: 1rem;
        }
        .contact-meta-wrapper {
          .ant-row {
            display: flex;
            align-items: center;
            .ant-col:first-of-type {
              display: flex;
              justify-content: center;
            }
            .ant-col {
              padding: 0.5rem 0;
              .cb-svg {
                width: 20px;
                height: 20px;
                opacity: 0.5;
              }
              .cb-user-icon {
                margin-top: -13px;
                height: 50px;
                width: 41px;
              }
              .cb-contact-data {
                &-type {
                  font-size: 12px;
                  color: #80868b;
                }
              }
              .cb-contact-address {
                a {
                  display: flex;
                  flex-direction: column;
                  span {
                    color: @primary-color;
                    margin-bottom: 4px;
                    .cb-contact-data-type {
                      color: #80868b;
                    }
                  }
                }
              }
            }
          }
          .cb-contact-location {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.cb-contact-edit-wrapper {
  .ant-modal-content {
    overflow: hidden;
    border-radius: 10px;
    .ant-modal-close {
      top: 12.5px;
      display: none;
    }
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      box-shadow: 0 0 10px silver;
      .ant-modal-title {
        display: flex;
        align-items: center;
        color: #202124;
        .ant-avatar {
          margin-right: 1rem;
        }
      }
    }
    .ant-modal-body {
      padding: 24px 0 0 0;
      max-height: 700px;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-input {
        border: 0;
        top: 4px;
        left: -12px;

        &:focus {
          box-shadow: none;
        }
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-form-item-label {
        overflow: unset;
      }
      .ant-form-item-control-input-content {
        position: relative;
        margin-bottom: 1.5rem;
      }

      .floating-label {
        position: absolute;
        top: 7px;
        transition: all 200ms;
        opacity: 0.5;
        z-index: 1;
        left: 0;
      }
      .float-label-input {
        font-size: 75%;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        color: #1a73e8;
      }
      .ant-form-item-label > label {
        width: 100%;
        &::after{
          margin: unset;
        }
      }

      .cb-dropdown {
        border: 0;
        background: transparent;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 7.9px 11px;
        color: #545454;
        font-size: 14px;
        line-height: 1.3;
        background-color: #fff;
        background-image: none;
        border-radius: 0px;
        transition: all 0.3s;
        left: -12px;

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      .cb-input-line {
        background-color: rgba(0,0,0,0.12);
        bottom: 25px;
        height: 1px;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
      }

      .cb-form-row:hover {
        .cb-empty-field {
          opacity: 0.4;
        }
      }

      .cb-edit-icons {
        height: 20px;
        flex-shrink: 0;
        margin-left: 25px;
        margin-right: 15px;
        margin-top: 7px;
        opacity: .6;
        text-align: center;
        width: 60px;
      }

      .cb-profile-icon {
        height: 50px;
        opacity: 0.4;
      }

      .cb-empty-field-wrapper {
        margin-top: 6px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cb-empty-field {
          opacity: 0;
          height: 20px;
          width: 20px;
          cursor: pointer;
        }

        .cb-add-field {
          height: 20px;
          width: 20px;
          fill: #1a73e8;
          cursor: pointer;
        }
      }

      .cb-form-footer {
        padding: 10px 16px;
        text-align: right;
        border-top: 1px solid #e8e8e8;
        border-radius: 0 0 0px 0px;
        margin-left: -23px;
        margin-right: -23px;
        display: flex;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        z-index: 999;
        background: #ffffff;
        padding: 8px 38px 5px;

        .cb-form-button {
          background-color: transparent;
          color: #1a73e8;
          color: var(--gm-colortextbutton-ink-color, #1a73e8);
          border: 0;
          font-size: 0.875rem;
        }

        .cb-submit-button:disabled {
          color:rgba(60,64,67,0.38)
        }
      }

      #emailAddresses-add {
        align-items: flex-end;
        margin-top: -45px;
        margin-bottom: 25px;
        float: right;
      }

      .cb-show-label {
        display: block;
      }

      .cb-hide-label {
        display: none;
      }

      .PhoneInput > input {
        border: transparent;
        height: 34px;

        &:focus {
          outline: none;
        }
      }

      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-right: -6px;
      }
      .ant-picker {
        border: 0;
        display: flex;
        .ant-picker-input > input {
          margin-left: -12px;
          margin-top: 4px;
        }
      }
      .ant-picker-focused {
        box-shadow: none;
      }

    }
    .ant-modal-footer {
      button {
        background-color: transparent;
        color: #1a73e8;
        color: var(--gm-colortextbutton-ink-color,#1a73e8);
        border: 0;
        font-size: 0.875rem;
      }
    }
  }
}

.cb-show-overlay {
  .ant-modal-body {
    pointer-events: none;
    overflow: hidden;
    opacity: 0.5;

    .ant-btn-loading-icon {
      color: #1a73e8;
    }
  }
}

@media only screen and (max-width: 575px) {
  .cb-contact-info-wrapper {
    max-width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    .ant-modal-content {
      height: inherit;
      border-radius: 0;
      .ant-modal-header {
        border-radius: 0;
      }
      .ant-modal-body {
        max-height: unset;
        height: calc(100vh - 80px);
        .cb-contact-modal-body {
          padding: 10px 12px;
          .cb-text-strong {
            font-size: 16px;
          }
          .contact-meta-wrapper {
            .ant-row {
              .ant-col {
                .ant-typography,
                .cb-contact-data,
                .cb-contact-address {
                  font-size: 12px;
                }
                .cb-contact-data {
                  &-type {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cb-contact-edit-wrapper {
    max-width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    .ant-modal-content {
      height: inherit;
      border-radius: 0;
      .ant-modal-header {
        border-radius: 0;
      }
      .ant-modal-body {
        max-height: unset;
        height: calc(100vh - 80px);
        .ant-form-item .ant-form-item-label {
          padding: 0;
        }
        .ant-form-item-label > label {
          height: 4px;
        }
        .cb-edit-icons {
          width: 45px;
          padding-right: 6px;
          margin-left: 0;
        }
        .cb-form-footer {
          .cb-button-space {
            right: 25px;
          }
        }
      }
    }
  }
}
