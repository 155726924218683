@import "../../styles/variables";

.account {
  padding: 1rem;
  overflow-y: auto;
  &-row {
    .account-col {
      .ant-card {
        box-shadow: 0 4px 22px rgba(202, 202, 202, 0.5);
        .ant-card-body {
          overflow-y: auto;
          max-height: 60vh;
        }
      }
    }
    .subscribed-user-wrapper {
      margin-bottom: 1rem;
      .ant-card {
        .ant-card-head {
          padding: 0 1rem;
        }
        .ant-card-body {
          padding: 0;
        }
      }
    }
    .plan-details-wrapper {
      padding: 1rem 0.25rem;
      .ant-card {
        .ant-card-body {
          // max-height: unset;
          .plan-detail-row {
            padding: 1rem 0;
            .ant-col {
              text-transform: capitalize;
            }
            .ant-col-xs-8 {
              text-align: end;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
      .cms-card-action {
        .ant-card-actions {
          opacity: 0;
        }
      }
    }
    .delete-account-wrapper {
      padding: 1rem 0.25rem;
      .ant-card {
        .ant-card-head {
          .ant-card-head-wrapper {
            .ant-card-head-title {
              .danger {
                color: #f5222d;
              }
            }
          }
        }
        .ant-card-body {
          display: flex;
          flex-direction: column;
          .warning-text {
            margin-bottom: 1rem;
          }
          button {
            width: fit-content;
          }
        }
      }
    }
  }
}
.cms-account-action {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  .cms-cancel-plan {
    background: #f5222d;
    border: #f5222d;
  }
}

@media only screen and (max-width: 768px) {
  .account {
    &-row {
      .account-col {
        .ant-card {
          .ant-card-body {
            max-height: unset;
          }
        }
      }
      .plan-details-wrapper {
        padding-right: 0;
        margin-bottom: 1rem;
      }
      .delete-account-wrapper {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .account {
    &-row {
      .subscribed-user-wrapper {
        .ant-card {
          .ant-card-body {
            table {
              tbody {
                tr {
                  td {
                    font-size: @mobile-text-font-size;
                  }
                }
              }
            }
          }
        }
      }
      .plan-details-wrapper {
        padding: 0;
        margin-bottom: 1rem;
        .ant-card {
          .ant-card-body {
            .plan-detail-row {
              padding: 0.5rem 0;
              .ant-col {
                font-size: @mobile-text-font-size;
              }
            }
          }
        }
      }
      .delete-account-wrapper {
        padding: 0;
        .ant-card {
          .ant-card-body {
            .warning-text {
              font-size: @mobile-text-font-size;
              line-height: 1.8em;
            }
          }
        }
      }
    }
  }
}
