.support {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-home-link {
    width: 100%;
    padding-left: 100px;
  }
  &-mail-icon {
    .cb-svg-lg {
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  &-heading,
  &-description,
  &-button,
  &-faqs {
    margin-top: 1rem;
    letter-spacing: 1.5px;
  }
}
