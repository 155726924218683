.cb-onboarding-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: 10px;
    .ant-modal-body {
      .steps-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cb-scheduler-content-wrapper {
          width: 70%;
          margin-top: 1rem;
          @media only screen and (max-width: 575px) {
            width: 100%;
          }
          .cb-time-picker-form {
            .ant-form-item {
              .ant-form-item-control {
                &-input {
                  &-content {
                    .cb-btn-dashboard {
                      button {
                        @media only screen and (max-width: 575px) {
                          &:first-child {
                            margin-left: 0;
                          }
                          &:last-child {
                            margin-right: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .step-img {
          display: flex;
          img {
            height: 300px;
          }
        }
        .step-title {
          padding-top: 2rem;
        }
        .step-content {
          display: flex;
          padding: 0.5rem 0;
          span {
            color: #8f8f8f;
          }
        }
      }
      .steps-action {
        margin-top: 1rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        button {
          min-width: 100px;
          margin: 0 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .cb-onboarding-modal {
    .ant-modal-content {
      .ant-modal-body {
        .steps-content-wrapper {
          .step-img {
            img {
              height: 200px;
            }
          }
          .step-title {
            h4 {
              font-size: 16px;
            }
          }
          .step-content {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
