.logs {
  padding: 2rem;
  overflow-y: auto;
  .cb-logs-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .heading-row {
      width: 70%;
      display: flex;
      align-items: center;
      .dashboard-link {
        z-index: 1;
      }
      .title {
        margin-left: -12.5%;
        .ant-typography {
          margin-bottom: 0;
        }
      }
    }
  }
  &-list {
    .ant-list-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      .sync-card-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .sync-card {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          box-shadow: 0 4px 22px rgba(202, 202, 202, 0.5);
          margin: 1rem 0;
          padding: 1.5rem 1rem;
          .sync-status {
            margin-bottom: 0.5rem;
          }
          .sync-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .ant-typography {
              display: grid;
              grid-auto-flow: column;
              align-items: center;
              justify-items: center;
              svg {
                cursor: pointer;
                margin-left: 4px;
                g {
                  path,
                  rect {
                    fill: @danger-color;
                  }
                }
              }
            }
            .sync-meta-info {
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .logs {
    .cb-logs-heading {
      .heading-row {
        width: 80%;
      }
    }
    &-list {
      .ant-list-items {
        .sync-card-wrapper {
          .sync-card {
            width: 80%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .logs {
    .cb-logs-heading {
      .heading-row {
        width: 100%;
        .title {
          margin-left: -25%;
        }
      }
    }
    &-list {
      .ant-list-items {
        .sync-card-wrapper {
          .sync-card {
            width: 100%;
            .sync-info {
              .sync-meta-info {
                div {
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .logs {
    &-list {
      .ant-list-items {
        .sync-card-wrapper {
          .sync-card {
            .sync-info {
              .ant-typography {
                gap: 8px;
                grid-auto-flow: row;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .logs {
    padding: 1rem;
    .cb-logs-heading {
      .heading-row {
        .dashboard-link {
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .title {
          margin-left: 0;
        }
      }
    }
    &-list {
      .ant-list-items {
        .sync-card-wrapper {
          .sync-card {
            padding: 1rem 0.5rem;
            flex-direction: column;
            .sync-status {
              margin-bottom: 1rem;
            }
            .sync-info {
              .sync-meta-info {
                div {
                  margin-right: 0.25rem;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
